<template>
  <div class="section-container signin change-password">
    <v-container>
      <v-row class="signin" justify="center">
        <v-col cols="12" md="6" class="right text-center">
          <div class="inner">
            <h2 class="text-center mb-5">Change Password</h2>
            <validation-observer ref="changeObserver" v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      :value="user.email"
                      class="form-control"
                      required
                      outlined
                      dark
                      background-color="#f0f1f6"
                      color="#000"
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Old Password"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="oldPassword"
                        class="form-control"
                        placeholder="Old Password"
                        :type="show3 ? 'text' : 'password'"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        counter
                        @click:append="show3 = !show3"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required|password"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="password"
                        class="form-control"
                        placeholder="New Password"
                        :type="show1 ? 'text' : 'password'"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirm Password"
                      :rules="{ required: true, confirmed: password }"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="confirmPassword"
                        class="form-control"
                        placeholder="Confirm Password"
                        :type="show2 ? 'text' : 'password'"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="text-center mt-5">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    primary
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import UserService from "@/services/UserService";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      show2: false,
      show1: false,
      show3: false,
      oldPassword: null,
      password: null,
      confirmPassword: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    submit() {
      this.loading = true;
      UserService.changePassword({
        oldPassword: this.oldPassword,
        newPassword: this.password,
        confirmPassword: this.confirmPassword,
        userId: this.user.userId,
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Password Changed Successfully",
              type: "success",
            },
            { root: true }
          );
          this.oldPassword = null;
          this.password = null;
          this.confirmPassword = null;
          this.$refs.changeObserver.reset();
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: error.response.data.errorMessage,
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
    },
  },
};
</script>
